var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "설비별 점검항목",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  editable:
                    _vm.editable && _vm.result.checkStatusCd !== "MCSC000015",
                  data: _vm.result.equipmentResultList,
                  merge: _vm.grid2.merge,
                  filtering: false,
                  columnSetting: false,
                  isExcelDown: false,
                  usePaging: false,
                  hideBottom: true,
                  noDataLabel: "설비에 대한 점검항목이 없습니다.",
                  selection: "multiple",
                  rowKey: "minEquipmentTypeCheckItemId",
                },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "customCol" &&
                        props.row["sopImprovementIds"] &&
                        props.row["ibmStepNames"]
                          ? _vm._l(
                              _vm.$comm.ibmTagItems(props.row),
                              function (item, index) {
                                return _c(
                                  "q-chip",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "margin-bottom": "4px !important",
                                    },
                                    attrs: {
                                      color: item.color,
                                      clickable: true,
                                      "text-color": "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.linkClick(item, props.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                    _c("c-assess-tooltip", {
                                      attrs: {
                                        ibmTooltip: item.ibmTooltip,
                                        ibmClassCd: item.ibmClassCd,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            )
                          : props.row["cbmFlag"] === "N"
                          ? void 0
                          : col.name === "gaugeWarn"
                          ? [
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#0300c1" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeWarnLcl) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                },
                                [_vm._v(" ~ ")]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#C10015" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeWarnUcl) + " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "gaugeExec"
                          ? [
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#0300c1" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeExecLcl) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                },
                                [_vm._v(" ~ ")]
                              ),
                              _c(
                                "font",
                                {
                                  staticStyle: {
                                    "font-size": "1.0em",
                                    "font-weight": "700",
                                  },
                                  attrs: { color: "#C10015" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.row.gaugeExecUcl) + " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "cbmAlert"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  class: null,
                                  attrs: {
                                    color: _vm.setTagColor(props.row),
                                    clickable: false,
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.setTagName(props.row)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "cbmValue"
                          ? [
                              _c("c-number-column", {
                                attrs: {
                                  editable: _vm.editable,
                                  col: col,
                                  props: props,
                                },
                                on: {
                                  datachange: function ($event) {
                                    return _vm.datachange(props, col)
                                  },
                                },
                                model: {
                                  value: props.row["cbmValue"],
                                  callback: function ($$v) {
                                    _vm.$set(props.row, "cbmValue", $$v)
                                  },
                                  expression: "props.row['cbmValue']",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm.result.checkStatusCd !== "MCSC000015" &&
                        col.name === "customCol"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "q-btn-group",
                                  {
                                    staticClass: "ColumInnerBtnGroup",
                                    attrs: { outline: "" },
                                  },
                                  [
                                    _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "red-6",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props,
                                              "1"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 개선요청등록 "),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "light-blue",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props,
                                              "2"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 즉시조치등록 "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _vm.isMultiple
                  ? _c(
                      "template",
                      { slot: "table-chip" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c(
                              "q-chip",
                              [
                                _c("q-avatar", {
                                  attrs: {
                                    icon: "push_pin",
                                    color: "blue-6",
                                    "text-color": "white",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "설비명(관리번호) : " +
                                        _vm.result.equipmentName +
                                        "(" +
                                        _vm.result.equipmentCd +
                                        ")"
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "정비요청", icon: "add" },
                              on: { btnClicked: _vm.linkClick2 },
                            })
                          : _vm._e(),
                        _vm.isMultiple &&
                        _vm.result.equipmentResultList.length > 0 &&
                        _vm.editable &&
                        _vm.result.checkStatusCd !== "MCSC000015"
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.updateItemUrl,
                                isSubmit: _vm.isSave2,
                                param: _vm.result.equipmentResultList,
                                mappingType: "PUT",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveItemInspection,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }