<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비별 점검항목"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :editable="editable && result.checkStatusCd!=='MCSC000015'"
          :data="result.equipmentResultList"
          :merge="grid2.merge"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="false"
          :usePaging="false"
          :hideBottom="true"
          noDataLabel="설비에 대한 점검항목이 없습니다."
          selection="multiple"
          rowKey="minEquipmentTypeCheckItemId"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
              <q-chip
                v-for="(item, index) in $comm.ibmTagItems(props.row)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="linkClick(item, props.row)">
                {{item.title}}
                <c-assess-tooltip 
                  :ibmTooltip="item.ibmTooltip"
                  :ibmClassCd="item.ibmClassCd"
                />
              </q-chip>
            </template>
            <template v-else-if="props.row['cbmFlag'] === 'N'">
            </template>
            <template v-else-if="col.name === 'gaugeWarn'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeWarnLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeWarnUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'gaugeExec'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeExecLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeExecUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'cbmAlert'">
              <q-chip
                :color="setTagColor(props.row)"
                :clickable="false"
                :class="null"
                text-color="white">
                {{setTagName(props.row)}}
              </q-chip>
            </template>
            <template v-else-if="col.name === 'cbmValue'">
              <c-number-column
                :editable="editable"
                :col="col"
                :props="props"
                v-model="props.row['cbmValue']"
                @datachange="datachange(props, col)"
              />
            </template>
            <div v-if="result.checkStatusCd!=='MCSC000015' && col.name==='customCol'">
              <q-btn-group outline class="ColumInnerBtnGroup">
                <q-btn
                  icon="add"
                  color="red-6"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props, '1')">
                  <q-tooltip>
                    개선요청등록
                  </q-tooltip>
                </q-btn>
                <q-btn
                  icon="add"
                  color="light-blue"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props, '2')">
                  <q-tooltip>
                    즉시조치등록
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </div>
          </template>
          <template v-if="isMultiple" slot="table-chip">
            <q-btn-group outline >
              <q-chip>
                  <q-avatar icon="push_pin" color="blue-6" text-color="white" /> 
                  {{ "설비명(관리번호) : " + result.equipmentName + '(' + result.equipmentCd + ')'}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="정비요청" v-if="editable" icon="add" @btnClicked="linkClick2" />
              <c-btn
                v-if="isMultiple && result.equipmentResultList.length > 0 && editable && result.checkStatusCd!=='MCSC000015'"
                :url="updateItemUrl"
                :isSubmit="isSave2"
                :param="result.equipmentResultList"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveItemInspection"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    result: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentName: '',
        checkStatusCd: '',
        minEquipmentCheckId: '',
        equipmentResultList: [],
        equipmentResultCbmList: [],
        checkDate: '',
      }),
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      tab: 'resultItem',
      grid2: {
        merge: [
          { index: 0, colName: "checkItemName" }
        ],
        columns: [
          
            {
              name: 'checkItemPartName',
              field: 'checkItemPartName',
              label: '부품',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              style: 'width:200px',
              align: 'left',
              sortable: true
            },
            {
              name: 'checkMethodName',
              field: 'checkMethodName',
              label: '검사방법',
              align: 'center',
              style: 'width:110px',
              sortable: false,
            },
            {
              required: true,
              name: 'judgmentResultTypeCd',
              field: 'judgmentResultTypeCd',
              label: '적합/부적합',
              align: 'center',
              style: 'width:130px',
              type: 'select',
              setHeader: true,
              comboItems: [
                { code: 'MCRC000005', codeName: '적합' },
                { code: 'MCRC000010', codeName: '부적합' },
                { code: 'MCRC000015', codeName: '대상외' },
              ],
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              label: '점검결과',
              align: 'left',
              style: 'width:200px',
              type: 'textarea',
              sortable: true
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              label: '판정기준',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              label: '점검시 안전사항',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
            
            {
              name: 'cbmTypeName',
              field: 'cbmTypeName',
              label: 'CBM 종류',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'gaugeWarn',
              field: 'gaugeWarn',
              label: '경고값',
              align: 'center',
              type: 'custom',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExec',
              field: 'gaugeExec',
              label: '실행값',
              align: 'center',
              type: 'custom',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'cbmValue',
              field: 'cbmValue',
              label: '기준값',
              align: 'center',
              type: 'custom',
              style: 'width:80px',
              sortable: false,
            },
            {
              name: 'cbmAlert',
              field: 'cbmAlert',
              label: '수치값 상태',
              align: 'center',
              style: 'width:100px',
              sortable: false,
              type: 'custom',
            },
            {
              name: 'customCol',
              field: 'customCol',
              label: '개선/진행상태',
              align: 'center',
              style: 'width:150px',
              type: 'custom',
              sortable: false
            },
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      isSave3: false,
      updateMode: false,
      equipmentCdName: '',
      printUrl: '',
      colorItems: [],
      requestImprRow: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.min.equipment.result.list.url;
      this.updateItemUrl = transactionConfig.sop.min.equipment.result.updateItem.url;
      this.printUrl = selectConfig.sop.min.equipment.maintain.print2.url;
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      // list setting
    },
    getCheckDetail() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        minEquipmentCheckId: this.result.minEquipmentCheckId,
      }
      this.$http.request((_result) => {
        this.result.equipmentResultList = _result.data
       
      },);
    },
    saveItemInspection() {
      if (this.$comm.validTable(this.grid2.columns, this.result.equipmentResultList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.result.equipmentResultList, _item => {
              _item.minEquipmentCheckId = this.result.minEquipmentCheckId;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getCheckDetail();
    },
    btnResult() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = {
        checkDate: this.result.checkDate,
        checkDeptName: this.result.checkDeptName,
        checkUserName: this.result.checkUserName,
        plantName: this.result.plantName,
        equipmentCd: this.result.equipmentCd,
        minEquipmentCheckId: this.result.minEquipmentCheckId
      };
      this.$http.request(
        _result => {
          let fileOrgNm = this.result.equipmentName + '(' + this.result.checkDate + ')' + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          console.log('result.col1', result.col1)
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.$refs['table2'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    innerBtnClicked(col, props, btn) {
      this.requestImprRow = props.row
      let requestContents = props.row.checkItemName;
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.minEquipmentTypeCheckItemId,
          ibmTaskTypeCd: 'ITT0000055',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: props.row.minEquipmentTypeCheckItemId,
          ibmTaskTypeCd: 'ITT0000055',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
            this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
              this.requestImprRow.ibmClassCds + ',' + item.ibmClassCd : item.ibmClassCd
            this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
              this.requestImprRow.ibmTooltip + '│' + item.ibmTooltip : item.ibmTooltip
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
      this.getCheckDetail();
    },
    setTagColor(row) {
      if (Number(row.gaugeExecLcl) >= Number(row.cbmValue) || Number(row.gaugeExecUcl) <= Number(row.cbmValue)) {
        return 'red'
      } else if (Number(row.gaugeWarnLcl) >= Number(row.cbmValue) || Number(row.gaugeWarnUcl) <= Number(row.cbmValue)) {
        return 'orange'
      } else {
        return 'blue'
      }
    },
    setTagName(row) {
      if (Number(row.gaugeExecLcl) >= Number(row.cbmValue) || Number(row.gaugeExecUcl) <= Number(row.cbmValue)) {
        return '정비요청필요'
      } else if (Number(row.gaugeWarnLcl) >= Number(row.cbmValue) || Number(row.gaugeWarnUcl) <= Number(row.cbmValue)) {
        return '경고'
      } else {
        return '정상'
      }
    },
    datachange(props) {
      if (props.row.editFlag !== 'C') {
        props.row.editFlag = 'U'
        props.row.chgUserId = this.$store.getters.user.userId
      }
    },
    linkClick2() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검항목을 선택하세요.', // 공기구를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let title = this.result.equipmentName;
        this.$_.forEach(selectData, _item => {
          title +=  '|' + _item.checkItemPartName + '|' + _item.checkItemName + '☞' + _item.cbmTypeName
           + '[경고값: ' + _item.gaugeWarnLcl + '~' + _item.gaugeWarnUcl + '|실행값: ' + _item.gaugeExecLcl + '~' + _item.gaugeExecUcl +']';
        })
        let date  =this.result.checkDate ? this.result.checkDate : this.result.checkScheduleDate
        this.popupOptions.title = '정비요청 상세';
        this.popupOptions.param = {
          woRequestId: '',
          plantCd: '',
          minEquipmentCheckId: this.result.minEquipmentCheckId,
          equipmentCd: this.result.equipmentCd,
          remark: title,
          woRequestName: date + ' 예방점검에 대한 정비요청',
          funcLocationCd: this.result.funcLocationCd,
          costCenterCd: this.result.costCenterCd,
          woRequestDt: date,
          woWorkTypeCd: 'WWTC000007',
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '90%';
        this.popupOptions.closeCallback = this.closePopup;

        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
